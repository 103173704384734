import React from "react"
import Helmet from "react-helmet"
import { graphql, Link } from "gatsby"
import Layout from "../components/Layout"
import { HTMLContent } from "../components/Content"
import GeneralServices from "../components/GeneralServices"
import MakItLion from "../images/makit-lion.svg"
import DesignCirclePartImage from "../images/mask@3x.png"
import { FormattedMessage, useIntl } from "react-intl"
import BlockTitle from "../components/BlockTitle"
import AccentureLogo from "../images/clients/accenture.svg"
import AmberLogo from "../images/clients/amber.svg"
import BaltaLogo from "../images/clients/balta.svg"
import FoodUnionLogo from "../images/clients/foodunion.svg"
import LatvenergoLogo from "../images/clients/latvenergo.svg"
import LmtLogo from "../images/clients/lmt.svg"
import TetLogo from "../images/clients/tet.svg"
import UpbLogo from "../images/clients/upb.svg"
import SomaLogo from "../images/clients/soma.svg"
import InstanticLogo from "../images/clients/instantic.svg"
import ZetaIndustryLogo from "../images/clients/zetaindustry.svg"
import GasoLogo from "../images/clients/gaso.svg"
import ErafLvImage from "../images/eraf_lv.svg"
import ErafEnImage from "../images/eraf_en.svg"
import Iso9001Logo from "../images/iso9001.svg"

const ClientLogo = ({ src, alt, className }) => {
  return (
    <div
      className={`md:w-1/2 lg:w-1/4 h-32 my-6 flex flex-col align-center justify-center`}
    >
      <img className={`self-center ${className}`} alt={alt} src={src} />
    </div>
  )
}

export const IndexPageTemplate = ({ title, content, latestProjects }) => {
  const intl = useIntl()
  const ErafImage = intl.locale === "lv" ? ErafLvImage : ErafEnImage

  return (
    <>
      <Helmet>
        <title>
          MAK IT - {intl.formatMessage({ id: "home.passioned" })}{" "}
          {intl.formatMessage({ id: "home.softDevelopment" })}
        </title>
      </Helmet>
      <div className="container relative flex flex-col mx-auto p-10 pb-64 lg:px-32 lg:py-20 lg:pb-32">
        <h2 className="z-10 font-semibold text-4xl lg:text-5xl tracking-wide text-gray-900 max-w-sm leading-none">
          <span className="text-green-500">
            <FormattedMessage id="home.passioned" />
          </span>
          <FormattedMessage id="home.softDevelopment" />
        </h2>
        <h3 className="z-10 mt-8 max-w-sm text-gray-500 font-light">
          <span className="font-semibold text-gray-900">
            <FormattedMessage id="home.passioned" />
          </span>{" "}
          <FormattedMessage id="home.adj" />
          <br />
          <FormattedMessage id="home.any" />
          <span className="text-orange-500">
            <FormattedMessage id="home.great" />
          </span>
          ,{" "}
          <span className="text-orange-500">
            <FormattedMessage id="home.strong" />
          </span>
          ,{" "}
          <span className="text-orange-500">
            <FormattedMessage id="home.powerful" />
          </span>
          <FormattedMessage id="home.emotion" />
        </h3>
        <div
          className="z-0 absolute w-56 h-56 lg:w-120 lg:h-120 -bottom-8 right-32 lg:top-24 rounded-full"
          style={{
            border: "solid 3px #f6f6f6",
          }}
        ></div>
        <img
          src={DesignCirclePartImage}
          alt=""
          className="z-0 absolute w-32 transform"
          style={{
            right: "26rem",
            bottom: "-2rem",
            "--transform-rotate": "200deg",
          }}
        />
        <img
          src={MakItLion}
          alt=""
          className="z-0 absolute w-64 lg:w-112 -bottom-16 right-0 lg:top-0 xl:right-40"
        />
      </div>

      <GeneralServices />

      <div className="container mx-auto pt-32 pb-8 px-10 md:px-20">
        <BlockTitle className="text-4xl text-gray-900 leading-tight font-semibold">
          <FormattedMessage id="home.projectsTitle" />
        </BlockTitle>
      </div>
      <div className="container mx-auto md:px-16">
        <div className="sm:flex align-center justify-center">
          {latestProjects.edges.map((item, i) => (
            <Link
              key={i}
              to={item.node.fields.slug}
              className="ProjectCardLink my-16 sm:w-1/3 flex flex-col align-center justify-center"
            >
              <img
                className="self-center"
                src={item.node.frontmatter.listImage.childImageSharp.fixed.src}
                alt={item.node.frontmatter.title}
                style={{ maxWidth: "90%", maxHeight: "90%" }}
              />
            </Link>
          ))}
        </div>
      </div>

      <div className="container mx-auto pt-10 pb-16 px-10 md:px-20">
        <BlockTitle className="text-4xl text-gray-900 leading-tight font-semibold">
          <FormattedMessage id="home.clientsTitle" />
        </BlockTitle>

        <div
          className="md:flex flex-wrap align-center justify-center py-10 px-12"
          style={{ filter: "grayscale(1)" }}
        >
          <ClientLogo className="h-10" alt="UPB" src={UpbLogo} />
          <ClientLogo className="h-10" alt="LMT" src={LmtLogo} />
          <ClientLogo className="h-16" alt="Balta" src={BaltaLogo} />
          <ClientLogo className="h-8" alt="Latvenergo" src={LatvenergoLogo} />
          <ClientLogo className="h-12" alt="Accenture" src={AccentureLogo} />
          <ClientLogo className="h-9" alt="tet" src={TetLogo} />
          <ClientLogo
            className="h-10"
            alt="Amber Beverage Group"
            src={AmberLogo}
          />
          <ClientLogo className="h-18" alt="GASO" src={GasoLogo} />
          <ClientLogo className="h-10" alt="Food Union" src={FoodUnionLogo} />
          <ClientLogo className="h-7" alt="Soma.lv" src={SomaLogo} />
          <ClientLogo
            className="h-10"
            alt="Zeta Industry"
            src={ZetaIndustryLogo}
          />
          <ClientLogo className="h-8" alt="Instantic" src={InstanticLogo} />
        </div>
      </div>

      {content && (
        <div className="container mx-auto pt-0 pb-32 px-10 lg:px-32 text-gray-500 font-light text-sm">
          <HTMLContent className="eraf-content" content={content} />
          <div className="md:flex items-center md:mt-16">
            <div className="flex flex-col align-center justify-center mt-16 md:mt-0 md:mr-16">
              <img
                src={Iso9001Logo}
                alt=""
                className="self-center h-40 md:h-24"
              />
            </div>
            <div className="flex flex-col align-center justify-center mt-16 md:mt-0">
              <img src={ErafImage} alt="" className="self-center h-16" />
            </div>
          </div>
        </div>
      )}
    </>
  )
}

const IndexPage = ({ data, location }) => {
  const { page, latestProjects } = data

  return (
    <Layout location={location}>
      <IndexPageTemplate
        title={page.frontmatter.title}
        content={page.html}
        latestProjects={latestProjects}
      />
    </Layout>
  )
}

export default IndexPage

export const IndexPageQuery = graphql`
  query IndexPage($id: String!, $langKey: String!) {
    page: markdownRemark(id: { eq: $id }) {
      html
      frontmatter {
        title
      }
    }
    latestProjects: allMarkdownRemark(
      limit: 3
      sort: { order: DESC, fields: [frontmatter___date] }
      filter: {
        frontmatter: { pageType: { eq: "project" } }
        fields: { langKey: { eq: $langKey } }
      }
    ) {
      edges {
        node {
          id
          fields {
            langKey
            slug
          }
          frontmatter {
            title
            mainDescription
            listImage {
              childImageSharp {
                fixed(width: 682, quality: 75) {
                  ...GatsbyImageSharpFixed
                }
              }
            }
          }
        }
      }
    }
  }
`
