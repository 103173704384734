import React, { useContext } from "react"
import Button from "./Button"
import AppDevThumbnail from "./thumbnails/AppDevThumbnail"
import SoftDevThumbnail from "./thumbnails/SoftDevThumbnail"
import UiUxThumbnail from "./thumbnails/UiUxThumbnail"
import { FormattedMessage } from "react-intl"
import { LanguagePrefixContext } from "./Layout"

const defaultColClassNames =
  "flex-1 text-gray-700 items-center text-center px-4 py-2 m-2"

const GeneralServices = (props) => {
  const langPrefix = useContext(LanguagePrefixContext)

  return (
    <div className="p-10 lg:py-24 bg-gray-200 text-center" {...props}>
      <div className="container flex flex-col lg:flex-row mx-auto">
        <AppDevThumbnail className={defaultColClassNames}>
          <p className="font-light text-gray-500">
            <FormattedMessage id="generalServices.whatApps" />
          </p>
        </AppDevThumbnail>
        <SoftDevThumbnail className={defaultColClassNames}>
          <p className="font-light text-gray-500">
            <FormattedMessage id="generalServices.whatSoftware" />
          </p>
        </SoftDevThumbnail>
        <UiUxThumbnail className={defaultColClassNames}>
          <p className="font-light text-gray-500">
            <FormattedMessage id="generalServices.whatDesign" />
          </p>
        </UiUxThumbnail>
      </div>
      <Button to={`${langPrefix}services`} outlined className="mt-12">
        <FormattedMessage id="generalServices.allOurServices" />
      </Button>
    </div>
  )
}

export default GeneralServices
